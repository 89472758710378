export const getKeyValue: any = (level: string) => {
  if (level === 'serial_numbers' || level === 'storagegrid') {
    return 'systemKey'
  }
  if (level === 'customer') {
    return 'customerId'
  }
  if (level === 'site') {
    return 'siteId'
  }
  if (level === 'group') {
    return 'customerGroup'
  }
  if (level === 'cluster') {
    return 'clusterId'
  }
  if (level === 'watchlist') {
    return 'watchlistId'
  }
  return 'customerId'
}
