import axios from 'axios'
import { API } from '../../../common/ApiURL'
import { TokenService } from '../../oauth/token.service'

export const fetchCustomerList = async (): Promise<any> => {
  const config = {
    headers: { authorizationToken: TokenService.getAppToken(), client: 'aiq-desktop', 'Content-Type': 'application/json' },
  }
  try {
    const { data } = await axios.get(API.customerList, config)
    return data
  } catch (error: any) {
    if (error.response.status === 401) {
      TokenService.refreshToken('UNAUTHENTICATED')
    }
  }
}
