export const envConfig = {
  local: {
    isProd: false,
    gql_url: 'https://stg.gql.aiq.netapp.com/',
    public_api_url: 'https://api.activeiq-stg.netapp.com/',
    cloudManager_base_Url: 'https://staging.api.bluexp.netapp.com/',
    cmAccess_tokeBase_url: 'https://api.activeiq-stg.netapp.com/',
    walkMe_url: 'https://cdn.walkme.com/users/3aa9795e32854b2a9b935f404c74d97d/test/walkme_3aa9795e32854b2a9b935f404c74d97d_https.js'
  },
  stg: {
    isProd: false,
    gql_url: 'https://stg.gql.aiq.netapp.com/',
    public_api_url: 'https://api.activeiq-dev.netapp.com/',
    cloudManager_base_Url: 'https://staging.api.bluexp.netapp.com/',
    cmAccess_tokeBase_url: 'https://api.activeiq-stg.netapp.com/',
    walkMe_url: 'https://cdn.walkme.com/users/3aa9795e32854b2a9b935f404c74d97d/test/walkme_3aa9795e32854b2a9b935f404c74d97d_https.js'
  },
  prod: {
    isProd: true,
    gql_url: 'https://gql.aiq.netapp.com/',
    public_api_url: 'https://api.activeiq.netapp.com/',
    cloudManager_base_Url: 'https://api.bluexp.netapp.com/',
    cmAccess_tokeBase_url: 'https://api.activeiq.netapp.com/',
    walkMe_url: 'https://cdn.walkme.com/users/3aa9795e32854b2a9b935f404c74d97d/walkme_3aa9795e32854b2a9b935f404c74d97d_https.js'
  },
}

export const environment:any = () => {
  const res = window.location.href
  if (res.includes('http://localhost')) {
    return envConfig.local
  }
  if (res.includes('https://activeiq-sustainability-tst.netapp.com')) {
    return envConfig.stg
  }
  if (res.includes('https://activeiq-sustainability.netapp.com')) {
    return envConfig.prod
  }
  return envConfig.stg
}
