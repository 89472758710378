import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { TokenService } from './components/oauth/token.service'
import { onError } from '@apollo/client/link/error'
import { environment } from './config/environment'
import { RetryLink } from '@apollo/client/link/retry'

const httpLink = new HttpLink({
  uri: environment().gql_url,
})

const authLink = setContext((_, { headers }) => {
  const token = TokenService.getAppToken()
  return {
    headers: {
      ...headers,
      authorization: token ?? '',
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors !== null) {
    graphQLErrors?.forEach(({ message, locations, path, extensions }) => {
      if (extensions?.code === 'UNAUTHENTICATED') {
        TokenService.refreshToken(message)
      }
    })
  }
})

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, _operation) => Boolean(error),
  },
})

const appLink = from([errorLink, httpLink, retryLink])

export const client = new ApolloClient({
  link: authLink.concat(appLink),
  cache: new InMemoryCache(),
})
