import * as React from 'react'
import { lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

const Home = lazy(async () => await import('./components/features/Dashboard/Dashboard'))
const NoAccessPage = lazy(async () => await import('./components/features/NoAccessPage/NoAccessPage'))

const Loading = lazy(async () => await import('./components/commons/loader/loader'))
const Routers = (): any => {
  const queryParams = new URLSearchParams(location.search)
  const queryString = queryParams.toString()
  return (
    <React.Suspense fallback={<Loading />}>
      <Routes>
        <Route path='*' element={<Navigate to='/addNssAccount' />} />
        <Route path='/' element={<Navigate to={`/dashboard?${queryString}`} />} />
        <Route path='/dashboard' element={<Home />} />
        <Route path='/addNssAccount' element={<NoAccessPage />} />
      </Routes>
    </React.Suspense>
  )
}

export default Routers
