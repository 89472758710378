import React, { createContext, useState, useEffect, useMemo, useLayoutEffect } from 'react'
import { IframeToken, NSSUser } from './../components/features/Dashboard/Dashboard-config'
import { AppStateService } from '../services/AppState/AppState.service'
import { fetchCustomerList } from './../components/apis/CustomerList/CustomerList'
import { API } from '../common/ApiURL'
import { getKeyValue } from './../utill/helper'
import { environment } from '../config/environment'
import axios from 'axios'
export type CustomerType = {
  systemCount: number
  customer_name: string
  customer_id: string
}

// type AppProviderProps = {
//   children: React.ReactNode
// }

export type UserInfoType =
  | {
    companyId: string
    companyName: string
    emailId: string
    firstName: string
    id: string
    isSam: string
    lastName: string
    type: string
  }
  | undefined
// create context
const AppContext: any = createContext(null)

const AppContextProvider = ({ children }: any): JSX.Element => {
  const [levelId, setLevelId] = useState()
  const [levelName, setLevelName] = useState<any>()
  const [userInfo, setUserInfo] = useState<any>()
  const [optionsValues, setOptionsValues] = useState()
  const [defaultValue, setDefaultValue] = useState()
  const [isLoading, setIsLoading] = React.useState(true)
  const [showCMLandingScreen, setShowCMLandingScreen] = useState(false)
  const [nssTokenAdded, setNssTokenAdded] = useState(false)

  useLayoutEffect(() => {
    const res = window.location.href
    let query: IframeToken
    try {
      query = JSON.parse(
        '{"' +
        decodeURI(
          res
            ?.substring(res.indexOf('?') + 1)
            ?.replace(/&/g, '","')
            ?.replace(/=/g, '":"'),
        ) +
        '"}',
      )
      // TODO: Cloud Manager Token storing
      if (query?.accessToken?.length > 0 && query?.accountId?.length > 0) {
        sessionStorage.setItem('cmSustainabilityIframeToken', query.accessToken)
        sessionStorage.setItem('cmSustainabilityAccountId', query.accountId)
        sessionStorage.setItem('cmSustainabilityDomain', 'https://' + query.domain)
        sessionStorage.setItem('isDemoMode', query.isDemoMode)
        registerToParentFrame()
        if ( query.isDemoMode !== 'true' ) {
          // Check auth0 token is working or not
          const config = {
            headers: { authorizationToken: query.accessToken, client: 'aiq-desktop', 'Content-Type': 'application/json' },
          }
          axios.get(API.userInfo, config).then( res => {
            setNssTokenAdded(true) // using auth0 token as current session.
            sessionStorage.setItem('cmSustainabilitySession', query.accessToken)
          }).catch( () => {
            getListofNSSUsers() // fallback flow
          })
        } else {
          setNssTokenAdded(true)
          sessionStorage.setItem('cmSustainabilitySession', '')
          setIsLoading(false)
        }
      } else if (query?.accessToken?.length > 0) {
        // TODO: Active IQ Token storing
        sessionStorage.setItem('session', query.accessToken)
        sessionStorage.setItem('cmSustainabilityDomain', query.domain)
        sessionStorage.setItem('levelId', query.levelId)
        sessionStorage.setItem('levelName', query.levelName)
        window['username' as any] = query.userName as any
        window['usertype' as any] = query.userType as any
      }
    } catch (e) { }
  }, [window.location.href])

  const readCMIframeToken = (): string => {
    const cmIframeToken = sessionStorage.getItem('cmSustainabilityIframeToken')
    return cmIframeToken ?? ''
  }

  const readCMAccountId = (): string => {
    const cmAccountId = sessionStorage.getItem('cmSustainabilityAccountId')
    return cmAccountId ?? ''
  }

  const setCurrentNssUser = (nssUser: NSSUser | ''): void => {
    sessionStorage.setItem('currentSustainabilityNssUser', JSON.stringify(nssUser))
  }

  const getCurrentNssUser = (): NSSUser => {
    try {
      let currentNssUser: any = sessionStorage.getItem('currentSustainabilityNssUser')
      currentNssUser = JSON.parse(currentNssUser) ?? {}
      return currentNssUser
    } catch (e) {
      return {}
    }
  }

  const getNssUsers = async (cmToken: string, cmAccountId: string): Promise<any> => {
    const url = `${API.cloudManager}${cmAccountId}/nss-keys?userNss=true`
    const request = await fetch(url, {
      headers: { authorization: `Bearer ${cmToken}` },
    })
    const nssUsersList = await request.json()
    return nssUsersList
  }

  const setCMToken = async (nssUser: NSSUser, tokenUrl: string): Promise<any> => {
    if (nssUser.id != null) {
      const url = `${tokenUrl}accountId=${readCMAccountId()}&nssKeyId=${nssUser.id}`
      const options = {
        headers: { authorizationToken: readCMIframeToken() },
      }
      const request = await fetch(url, options)
      return await request.json()
    }
  }

  const getListofNSSUsers = (isRefresh?: boolean): void => {
    const cmToken = readCMIframeToken()
    const cmAccountId = readCMAccountId()
    getNssUsers(cmToken, cmAccountId)
      .then((nssUsersList: any) => {
        if (nssUsersList.length !== 0) {
          setCurrentNssUser(nssUsersList.length > 0 ? nssUsersList[0] : '')
          setCMToken(getCurrentNssUser(), API.generateToken)
            .then((res) => {
              if (res?.accessToken.length > 0) {
                setNssTokenAdded(true)
                sessionStorage.setItem('cmSustainabilitySession', res.accessToken)
                if (isRefresh === true) {
                  window.location.reload()
                }
              } else {
                setShowCMLandingScreen(() => {
                  return true
                })
                initCMConfig()
              }
            })
            .catch((e) => {
              setShowCMLandingScreen(() => {
                return true
              })
              initCMConfig()
            })
        } else {
          setShowCMLandingScreen(() => {
            return true
          })
          initCMConfig()
        }
      })
      .catch((e) => {
        setShowCMLandingScreen(() => {
          return true
        })
        initCMConfig()
      })
  }

  window.addEventListener('message', (e) => {
    if (e.data.type === 'SERVICE:NSS-ADDED') {
      window.location.reload()
    }
  })

  const initCMConfig = (): void => {
    sessionStorage.setItem('cmSustainabilitySession', '')
    window.parent.postMessage(
      {
        type: 'SERVICE:ADD-NSS',
        payload: { userNss: true },
      },
      '*',
    )
  }

  const registerToParentFrame = (): void => {
    setTimeout(() => {
      window.parent.postMessage(
        {
          type: 'SERVICE:READY',
        },
        '*',
      )
    })
  }

  const loadWalkmeScript = ({ url, username, userType }: { url: string, username: string, userType: string}): void => {
    const walkmeConfig = '_walkmeConfig'
    const uName = 'username'
    const uType = 'userType'
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!window[walkmeConfig as any]) {
      const walkme = document.createElement('script')
      walkme.type = 'text/javascript'
      walkme.async = true
      walkme.src = url
      const s = document.getElementsByTagName('script')[0]
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain, @typescript-eslint/strict-boolean-expressions
      if (s && s.parentNode) {
        s.parentNode.insertBefore(walkme, s)
        window[walkmeConfig as any] = {
          smartLoad: true,
          cdhiUrl: '/CDhiddenIframe.compress.html'
        } as any
        window[uName as any] = username as any
        window[uType as any] = userType as any
      }
    }
  }

  useEffect(() => {
    if ( ((nssTokenAdded && isBlueXP()) || !isBlueXP()) && !showDemoPage() )  AppStateService.getUserInfo()
    AppStateService.userInfo().subscribe((userInfo: UserInfoType) => {
      setUserInfo(userInfo)
      loadWalkmeScript({ url: environment().walkMe_url, username: userInfo?.id as any, userType: userInfo?.type as any })
    })
  }, [nssTokenAdded])

  const isBlueXP = (): boolean => {
    const cloudManagerDomains = ['staging.console.bluexp.netapp.com', 'staging.cloudmanager.netapp.com', 'cloudmanager.netapp.com', 'console.bluexp.netapp.com']
    const isBlueXP = cloudManagerDomains.some((el) => sessionStorage.getItem('cmSustainabilityDomain')?.includes(el))
    return isBlueXP
  }

  useEffect(() => {
    const isBlue = isBlueXP()
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (isBlue && !!userInfo && !defaultValue && userInfo?.type === 'End User') {
      fetchCustomerList()
        .then((res) => {
          if (res?.customers?.list.length !== 0 && res?.customers?.list.length !== undefined) {
            const customerList: any = []
            res?.customers?.list.forEach((element: CustomerType, index: any) => {
              customerList.push({
                value: element.customer_id,
                label: element.customer_name,
                label2: element.customer_name,
                isDisabled: false,
                disabledTitle: element.customer_name,
                key: index,
              })
            })
            setDefaultValue(customerList[0])
            setLevelId(customerList[0].value)
            setLevelName(getKeyValue('customer'))
            setOptionsValues(customerList)
            setIsLoading(false)
            // customerListService.updateCustomer(customerList[0])
          }
        })
        .catch(() => {
          setIsLoading(false)
        })
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    } else if (userInfo !== undefined && !!userInfo && !defaultValue) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      const levelId: any = sessionStorage.getItem('levelId')
      const levelName: any = sessionStorage.getItem('levelName')
      setLevelId(levelId)
      setLevelName(getKeyValue(levelName))
      setIsLoading(false)
    }
  }, [userInfo, isBlueXP, defaultValue])

  const showDemoPage = (): boolean => {
    if ((userInfo?.type === 'INTERNAL' && isBlueXP()) || (sessionStorage.getItem('isDemoMode') === 'true' && isBlueXP())) {
      return true
    } else {
      return false
    }
  }

  const values = useMemo(() => {
    return {
      levelId,
      userInfo,
      isBlueXP,
      optionsValues,
      defaultValue,
      setDefaultValue,
      isLoading,
      setLevelId,
      showCMLandingScreen,
      showDemoPage,
      levelName,
      getListofNSSUsers,
      setIsLoading
    }
  }, [levelId, userInfo, isBlueXP, optionsValues, defaultValue, setDefaultValue, isLoading, levelName, setLevelId, showCMLandingScreen, showDemoPage, getListofNSSUsers, setIsLoading])
  return (
    // the Provider gives access to the context to its children
    <AppContext.Provider value={values}>{children}</AppContext.Provider>
  )
}

export { AppContext, AppContextProvider }
