import { Subject } from 'rxjs'
import { fetchUserInfo } from '../../components/apis/UserInfo/UserInfo.service'

const subject = new Subject<UserInfoType>()

export type UserInfoType = {
  companyId: string
  companyName: string
  emailId: string
  firstName: string
  id: string
  isSam: string
  lastName: string
  type: string
}

export const AppStateService = {
  updateUserInfo: (customer: UserInfoType) => {
    subject.next(customer)
  },
  userInfo: () => subject.asObservable(),

  getUserInfo(): void {
    fetchUserInfo().then(
      (res: { results: UserInfoType }) => {
        this.updateUserInfo(res.results)
      },
      (err: any) => {
        // eslint-disable-next-line no-console
        console.log('error', err)
      },
    )
  },
}
