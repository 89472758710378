import { BlueXPService } from './bluexp.service'

export const TokenService = {
  getAppToken(): string {
    let session: string | null
    if (BlueXPService.isBlueXP()) {
      session = sessionStorage.getItem('cmSustainabilitySession')
    } else {
      session = sessionStorage.getItem('session')
    }
    return session ?? ''
  },

  refreshToken(message: any): void {
    if (BlueXPService.isBlueXP()) {
      window.location.reload()
    } else {
      window.parent.postMessage({ type: 'AIQ-TOKEN_EXPIRED', value: message }, '*')
    }
  },
}
