import { createSlice } from '@reduxjs/toolkit'

type InitialState = {
  count: number
}
const initialState: InitialState = {
  count: 0,
}

const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.count++
    },
    decrement: (state) => {
      state.count--
    },
    reset: (state) => {
      state.count = initialState.count
    },
  },
})

export default counterSlice.reducer
export const { increment, decrement, reset } = counterSlice.actions
