import { ApolloProvider } from '@apollo/client'
import { client } from './graphql'
import Routers from './routes'
import { AppContextProvider } from './context/AppContext'
const App = (): any => {

  return (
    <AppContextProvider>
      <ApolloProvider {...{ client }}>
        <Routers />
      </ApolloProvider>
    </AppContextProvider>
  )
}
export default App
