export const BlueXPService = {
  isBlueXP(): boolean {
    const cloudManagerDomains = ['staging.console.bluexp.netapp.com', 'staging.cloudmanager.netapp.com', 'console.bluexp.netapp.com', 'cloudmanager.netapp.com']
    const isBlueXP = cloudManagerDomains.some((el) => sessionStorage.getItem('cmSustainabilityDomain')?.includes(el))
    return isBlueXP
  },
  getDomainName(): string {
    return sessionStorage.getItem('cmSustainabilityDomain') ?? ''
  },
}
